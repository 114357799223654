<template>
  <div class="main-content">
    <b-card>
      <good-data-table
        ref="transfer_money"
        :button-label="$t('Transfer')"
        @add-new-info="New_Transfer"
        :columns="columns"
        :no-serial="true"
        :total-column="'response.responseData.total'"
        :data-column="'response.responseData.data'"
        :api-endpoint="'/account/transfer-money'"
      />
    </b-card>

    <validation-observer ref="Create_transfer_money">
      <b-modal hide-footer size="lg" v-model="transferModal" :title="editmode ? $t('Edit') : $t('Add')">
        <b-form @submit.prevent="Submit_transfer_money">
          <b-row>
            <!-- date  -->
            <b-col lg="6" md="6" sm="12">
              <validation-provider name="date" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group :label="$t('Date') + ' ' + '*'">
                  <b-form-input :state="getValidationState(validationContext)" aria-describedby="date-feedback" type="date" v-model="transfer.date"></b-form-input>
                  <b-form-invalid-feedback id="OrderTax-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Amount  -->
            <b-col lg="6" md="6" sm="12">
              <validation-provider name="Amount" :rules="{ required: true, regex: /^\d*\.?\d*$/ }" v-slot="validationContext">
                <b-form-group :label="$t('Amount') + ' ' + '*'">
                  <b-form-input
                    :state="getValidationState(validationContext)"
                    aria-describedby="Amount-feedback"
                    label="Amount"
                    type="number"
                    :placeholder="$t('Amount')"
                    v-model.number="transfer.amount"
                  ></b-form-input>
                  <b-form-invalid-feedback id="Amount-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- From Account -->
            <b-col lg="6" md="6" sm="12" v-if="!editmode">
              <validation-provider name="From_Account" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('From Account') + ' ' + '*'">
                  <v-select
                    :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="transfer.from_account_id"
                    :reduce="label => label.value"
                    :placeholder="$t('Choose Account')"
                    :options="accounts.map(accounts => ({ label: accounts.account_name, value: accounts.id }))"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- To Account -->
            <b-col lg="6" md="6" sm="12" v-if="!editmode">
              <validation-provider name="To_Account" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('To Account') + ' ' + '*'">
                  <v-select
                    :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="transfer.to_account_id"
                    :reduce="label => label.value"
                    :placeholder="$t('Choose Account')"
                    :options="accounts.map(accounts => ({ label: accounts.account_name, value: accounts.id }))"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" class="mt-3">
              <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
              <div v-once class="typo__p" v-if="SubmitProcessing">
                <div class="spinner sm spinner-primary mt-3"></div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import { showDangerNotification, showErrorNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/account`
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { isEmpty } from '@/utilities'

export default {
  components: {
    GoodDataTable,
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      transferModal: false,
      editmode: false,
      transfer: {
        id: '',
        from_account_id: '',
        to_account_id: '',
        amount: '',
        date: new Date().toISOString().slice(0, 10),
      },
    }
  },
  computed: {
    ...mapGetters('account', ['accounts']),
    columns() {
      return [
        {
          label: this.$t('Date'),
          field: 'date',
        },
        {
          label: this.$t('From Account'),
          field: 'from_account',
        },

        {
          label: this.$t('To Account'),
          field: 'to_account',
        },

        {
          label: this.$t('Amount'),
          field: 'amount',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_transfer_money(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_transfers_money(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
  },

  methods: {
    ...mapActions('account', ['getAccountBasic']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    //------------- Submit Validation
    Submit_transfer_money() {
      this.$refs.Create_transfer_money.validate().then(success => {
        if (!success) {
          showDangerNotification(this, 'Please fill all the fields')
        } else {
          if (!this.editmode) {
            this.Create_transfer_money()
          } else {
            this.Update_transfers_money()
          }
        }
      })
    },

    //------------------------------ Modal  (create transfer) -------------------------------\\
    New_Transfer() {
      this.reset_Form()
      this.editmode = false
      this.transferModal = true
    },

    //------------------------------ Modal (Update transfer) -------------------------------\\
    Edit_transfer_money(transfer) {
      this.reset_Form()
      this.transfer = transfer
      this.editmode = true
      this.transferModal = true
    },

    //--------------------------Get ALL Categories & Sub account ---------------------------\\

    //----------------------------------Create new transfer ----------------\\
    Create_transfer_money() {
      if (this.transfer.from_account_id === this.transfer.to_account_id) {
        showDangerNotification(this, 'Both account cant be the same')
        return
      }

      this.SubmitProcessing = true
      axios
        .post(apiUrl + '/transfer-money', {
          from_account_id: this.transfer.from_account_id,
          to_account_id: this.transfer.to_account_id,
          amount: this.transfer.amount,
          date: this.transfer.date,
        })
        .then(response => {
          this.SubmitProcessing = false
          this.transferModal = false
          showSuccessNotification(this, 'Transfer Made')
          this.$refs.transfer_money.loadItems()
        })
        .catch(e => {
          this.transferModal = false
          const { data } = e?.response
          this.SubmitProcessing = false
          const error = data?.error
          if (!isEmpty(error)) {
            showErrorNotification(this, error)
          } else {
            showErrorNotification(this, 'Someting went wrong while transfer')
          }
        })
    },

    //---------------------------------- Update transfer ----------------\\
    Update_transfers_money() {
      this.SubmitProcessing = true
      axios
        .put(apiUrl + '/transfer-money/' + this.transfer.id, {
          from_account_id: this.transfer.from_account_id,
          to_account_id: this.transfer.to_account_id,
          amount: this.transfer.amount,
          date: this.transfer.date,
        })
        .then(response => {
          this.transferModal = false
          this.SubmitProcessing = false
          showSuccessNotification(this, 'Tranfer Done')
          this.$refs.transfer_money.loadItems()
        })
        .catch(e => {
          this.transferModal = false
          const { data } = e?.response
          this.SubmitProcessing = false
          const error = data?.error
          if (!isEmpty(error)) {
            showErrorNotification(this, error)
          } else {
            showErrorNotification(this, 'Someting went wrong while transfer')
          }
        })
    },

    //--------------------------- reset Form ----------------\\

    reset_Form() {
      this.transfer = {
        id: '',
        from_account_id: '',
        to_account_id: '',
        amount: '',
        date: new Date().toISOString().slice(0, 10),
      }
    },

    //--------------------------- Remove transfer----------------\\
    Remove_transfers_money(props) {
      axios
        .delete(apiUrl + '/transfer-money/' + props.id)
        .then(() => {
          this.$refs.transfer_money.loadItems()
          showSuccessNotification(this, 'Deleted successfully')
        })
        .catch(() => {
          showErrorNotification(this, 'Failed to delete')
        })
    },
  }, //end Methods

  //----------------------------- Created function-------------------

  mounted: function () {
    this.getAccountBasic()
    this.$refs.transfer_money.loadItems()
  },
}
</script>